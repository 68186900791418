<template>
  <ul class="statistic-cards-list row">
    <li
      v-for="category in statisticCategories"
      :key="category.id"
      class="col-3"
    >
      <div class="statistic-card">
        <h2>{{ category.title }}</h2>

        <div class="d-flex">
          <div class="statistic-card-graphs">
            <AppGraphs
              :base-value="baseCount"
              :values="category.statistic"
              :colors="category.colors"
            />
          </div>
          <div class="statistic-card-content">
            <div class="statistic-card-amount">
              <span>$</span>

              <span class="amount-big">
                {{ amountUpdate(category.amount)[0] }}
              </span>

              <small>.{{ amountUpdate(category.amount)[1] }}</small>
            </div>

            <div
              class="statistic-card-profit"
              :class="{
                [`color-${
                  category.profit.direction === 'top' ? 'success' : 'danger'
                }`]: true
              }"
            >
              <div class="d-flex align-items-center">
                <span>{{ category.profit.value }}</span>
                <svg-icon
                  class="ms-2"
                  :icon="
                    category.profit.direction === 'top'
                      ? 'top-arrow'
                      : 'bottom-arrow'
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>
<script>
import AppGraphs from "../ui/AppGraphs.vue";
import SvgIcon from "../ui/SvgIcon.vue";
import { getId } from "@/utills/getId";
export default {
  name: "PaymentStatistic",
  components: { SvgIcon, AppGraphs },
  data() {
    return {
      baseCount: 70,
      statisticCategories: [
        {
          title: "Top Ups",
          id: getId(),
          amount: "640.00",
          profit: {
            value: "19%",
            direction: "top"
          },

          statistic: [60, 45],
          colors: ["#27AE60", "#E0E0E0"]
        },
        {
          title: "eSIM",
          id: getId(),
          amount: "215.00",
          profit: {
            value: "7%",
            direction: "bottom"
          },

          statistic: [50, 39],
          colors: ["#2F80ED", "#E0E0E0"]
        },
        {
          title: "Numbers",
          id: getId(),
          amount: "170.00",
          profit: {
            value: "10%",
            direction: "top"
          },

          statistic: [60, 45],
          colors: ["#2F80ED", "#E0E0E0"]
        },
        {
          title: "Refunds",
          id: getId(),
          amount: "36.98",
          profit: {
            value: "10%",
            direction: "top"
          },

          statistic: [50, 50],
          colors: ["#EB5757", "#E0E0E0"]
        }
      ]
    };
  },

  methods: {
    amountUpdate(amount) {
      return amount.split(".");
    }
  }
};
</script>

<style lang="scss">
.statistic-card {
  background: $color-contrast-light;
  border-radius: $border-radius;
  padding: 20px 30px 18px 30px;
  box-shadow: $shadow-700;
  h2 {
    margin-bottom: 20px;
    line-height: 1;
  }
}
.statistic-card-amount {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 5px;
  line-height: 1;
  small {
    font-size: 0.687em;
  }
}

.statistic-card-graphs {
  width: 60px;
}
.statistic-card-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
</style>
